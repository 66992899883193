import React from 'react';
import CTA from './CTA';
import HeaderSocials from './HeaderSocials';
import ME from '../../assets/me.png';
import './header.css';

function Header() {
    return (
        <header className=' h-[100vh] overflow-hidden mb-[2rem] sm:mb-0'>
            <div className="m-auto h-[100%] relative">
                <div className='flex  flex-col-reverse sm:flex-row sm:justify-around items-center h-[100%]'>
                    <div className='text-center'>
                        <h5>Hello I&#39;m</h5>
                        <h1>Burak</h1>
                        <h5 className="text-light">Frontend Developer</h5>
                        <CTA /> 
                    </div>

                    <div className="me mt-8 sm:mt-0">
                        <img src={ME} alt="Burak" />
                    </div>
                </div>

                <HeaderSocials />
            </div>
        </header>
    );
}

export default Header;
