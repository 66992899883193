import React from 'react';
import {SiLinkedin, SiGithub } from 'react-icons/si';


function HeaderSocials() {
    return (
        <div className='flex flex-col items-center gap-3 fixed left-2 xl:left-[15%] bottom-12 after:content-[""] after:w-[1px] after:h-8 after:bg-[#65b650]'>
            <a href="https://linkedin.com/in/burak-caniklioglu/" target='_blanked'><SiLinkedin /></a>
            <a href="https://github.com/burak-caniklioglu" target='_blanked'><SiGithub /></a>
        </div>
    );
}

export default HeaderSocials;