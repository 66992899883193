import React from 'react';
import Languages from '../Languages';
import './about.css';

function About() {
    return (
        <section
            id="about"
            className="flex  w-full flex-col sm:flex-row items-center justify-around"
        >
            <div className=' w-10/12  m-auto  sm:m-0 sm:w-1/2 '>
                <Languages />
            </div>
            <div className='w-10/12  m-auto  sm:w-1/2 '>
                <h1>
          About <span className="text-[#65b650]">Me</span>
                </h1>
                <div className='text-justify sm:mr-4'>
                    <ul>
                        <li className='leading-5 mb-4 '>
                A curious developer with high learning skills and quickly adapts to
          new technologies.
                        </li>
                        <li className='leading-5 mb-4'>
                        Attention to detail (errors, pixel perfection,
          responsive designs)
                        </li>
                        <li className='leading-5 mb-4'>
                        Persistent in overcoming challenges, explores
          different ways to produce effective solutions.
                        </li>
                        <li className='leading-5 mb-4'>
                        Research different
          resources and algorithms to make the most efficient products.
                        </li>
                        <li className='leading-5 mb-4'>
                        Friendly
          management skills and adaptability to new team members, Willingness to
          not only learn but teach for team development.
                        </li>
                        <li className='leading-5 mb-4'>
                        Curious to learn about
          various technologies, tools, frameworks and libraries.
                        </li>
                        <li className='leading-5 mb-4'>
                        Front End
          Development skills certified by Clarusway, Patika.dev, Create Your Own
          User Interface designs
                        </li>
                        <li className='leading-5 mb-2'>
                        Especially React.js and Next.js Projects
          experience with Bootstrap, Tailwind,Material Ui, Sass. Experience the
          rest-api
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default About;
