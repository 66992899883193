import React, {useRef} from 'react';
import './contact.css';
import { useForm } from 'react-hook-form';
import {MdOutlineMail } from 'react-icons/md';
import { ImWhatsapp } from 'react-icons/im';
import emailjs from '@emailjs/browser';

function Contact() {
    const form = useRef();
    const {
        handleSubmit, register, formState: { errors }, setValue,
        
    } = useForm();

    const sendEmail = () => {
    
        emailjs.sendForm('service_gy8mjaf', 'template_m7v6azp', form.current, '8OVW5K8HG5oJBYeNd')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        
        setValue('fullname', '');
        setValue('email', '');
        setValue('message', '');

        window.scrollTo(0, 0);

    };

    

    return (
        <section id='contact' className='h-[100vh]'>
            <h1 className='text-center mb-4'>
                <span className="text-[#65b650] ">Contact </span>
            Me</h1>  

            <div className="flex flex-col-reverse sm:flex-row gap-8 justify-center sm:mt-[5rem]">
                <form ref={form} className='mx-auto max-w-screen-sm w-[60%]' onSubmit={handleSubmit(sendEmail)} >
                    <div className="mb-4">
                        
                        <input type="text"  name='fullname' id="fullname" autoFocus {...register('fullname', {
                            required: 'Please enter your full name',
                        })} className={'w-full p-2 rounded-lg bg-[#6fb15f] placeholder:text-[#c7dac2]'}
                        placeholder="Fullname" />
                        {errors.fullname && (
                            <div className="text-red-500">{errors.fullname.message}</div>
                        )}
                    </div>
                    <div className="mb-4">
                        
                        <input type="email" name='email'  id="Email" autoFocus {...register('email', {
                            required: 'Please enter your email',
                        })} className={'w-full p-2 rounded-lg bg-[#6fb15f] placeholder:text-[#c7dac2]'}
                        placeholder="Email" />
                        {errors.email && (
                            <div className="text-red-500">{errors.email.message}</div>
                        )}
                    </div>

                    <div className="mb-4">
                        <textarea type="text" id="message"  name='message' autoFocus {...register('message', { required: 'Please enter your message', minLength: { value: 15, message: 'Your message must be longer than 15 characters.' } })} className={'w-full resize-none p-2 rounded-lg bg-[#6fb15f] placeholder:text-[#c7dac2]'} rows="3" placeholder='Your Message' ></textarea>
                        {errors.message && (
                            <div className="text-red-500">{errors.message.message}</div>
                        )}
                    </div>
                    
                    <div className="mb-4 flex justify-between">
                        
                        <button className={'btn '}>Send</button>
                    </div>


                </form>
                <div className='flex flex-col gap-8 sm:justify-between'>
                    <div className='flex flex-col m-auto sm:m-0 w-[60%] sm:w-full justify-center items-center border rounded-lg p-3'>
                        <MdOutlineMail/>
                        <h4 className='text-sm'>Email</h4>
                        <h5 className='text-xs'>burakcaniklioglu@gmail.com</h5>
                        <a className='text-sm' href="mailto:burakcaniklioglu@gmail.com">Send a message</a>
                    </div>
                    <div className='flex flex-col m-auto sm:m-0 w-[60%] sm:w-full justify-center items-center border rounded-lg p-3'>
                        <ImWhatsapp/>
                        <h4 className='text-sm'>Whatsapp</h4>
                        <h5 className='text-xs'>+90 536 477 71 57</h5>
                        <a className='text-sm' href="https://wa.me/+905364777157">Get contact</a>
                    </div>
                    <div></div>
                </div>
            </div>
        </section>
    );
}

export default Contact;