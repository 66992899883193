import React from 'react';
import './navbar.css';
import {GoHome } from 'react-icons/go';
import {FiUser,FiBookOpen} from 'react-icons/fi';
import {TiMessages} from 'react-icons/ti';


const Navbar = () => {
    return (
        <nav className='bg-[#000000] opacity-50 h-max px-3 py-7  fixed top-[37%] lg:right-[5%] right-[1rem] z-10 hidden sm:flex flex-col gap-3 rounded-[2rem] '>
            <a className='bg-transparent p-2   text-lg ' href="#"><GoHome /></a>
            <a className='bg-transparent p-2 text-lg' href="#about"><FiUser /></a>
            <a className='bg-transparent p-2 text-lg' href="#experience"><FiBookOpen /></a>
            <a className='bg-transparent p-2 text-lg ' href="#contact"><TiMessages /></a>
            
        </nav>
    );
};

export default Navbar;