import React from 'react';
import About from './components/about/About';
import Header from './components/header/Header';
import Navbar from './components/navbar/Navbar';
import Experience from './components/experience/Experience';
import Contact from './components/contact/Contact';
import { useEffect } from 'react';

function App() {

    useEffect(() => {
        window.scrollTo(0, 0);

    },[]);

    return (
        <>
            <div className='max-w-4xl m-auto'>
                <Header />
                <Navbar />
                <About />
                <Experience />
                <Contact />
            </div>
        </>
    );
}

export default App;
