import React from 'react';
import CV from '../../assets/Burak_Caniklioglu_Resume.pdf';

function CTA() {
    return (
        <div className='cta'>
            <a className='btn' href={CV} download>Download CV</a>
            <a className='btn btn-primary' href="#contact">Let&#39;s Talk</a>
        </div>
    );
}

export default CTA;