import React from 'react';
// import 'experience.css';

function Experience() {
    return (
        <section id='experience' className='  '>
            <h1 className='text-center mb-4'>
          My <span className="text-[#65b650] ">Portfolio</span>
            </h1>

            <div>
                <div className='flex flex-col sm:flex-row m-auto  sm:gap-8 h-[30rem] sm:h-[12rem]  justify-around items-center  mb-16'>
                    <div className=''>
                        <img className='rounded-xl  object-cover sm:px-0 px-8 sm:h-[12rem] ' src="https://res.cloudinary.com/db130iptr/image/upload/v1668696936/ringo_wk225d.png" alt="ringofsilver" />
                    </div>
                    <div className='w-5/6 mx-auto sm:m-0 sm:w-1/2 flex flex-col sm:justify-between h-[100%]'>
                        <h2 className='text-xl text-[#65b650]'>RingofSilver Project</h2>
                        <p className='text-justify'>An e-commerce site with all APIs programmed with Next.js written by me. Users can buy the products they like
by credit card or bank transfer method. They can see their order history and track their cargo.</p>
                        <div >
                            <a className='btn mr-4' target="_blanked" href="https://ringofsilver.net">Live</a>
                        </div>
                    </div>
                </div>
                
                <div className='flex flex-col sm:flex-row m-auto   sm:gap-8 h-[30rem] sm:h-[12rem]  justify-around items-center  mb-16'>
                    <div className=''>
                        <img className='rounded-xl object-cover px-8 sm:px-0 sm:h-[12rem] ' src="https://res.cloudinary.com/db130iptr/image/upload/v1668696828/ikinciel_xwliwl.png" alt="ikinciel" />
                    </div>
                    <div className='w-5/6 mx-auto sm:m-0  sm:w-1/2 flex flex-col sm:justify-between h-[100%]'>
                        <h2 className='text-xl text-[#65b650]'>Ikinciel Project</h2>
                        <p className='text-justify'>In the project, users can display their second-hand goods, bid on the products they want to buy, or buy them
directly.</p>
                        <div >
                            <a className='btn mr-4' target="_blanked" href="https://ikinci-el-project-burak-caniklioglu.vercel.app/">Live Demo</a>
                            <a className='btn btn-primary' target="_blanked"  href="https://github.com/burak-caniklioglu/ikinci-el-project">Github</a>
                        </div>
                    </div>
                </div>


                <div className='flex flex-col sm:flex-row m-auto   sm:gap-8 h-[30rem] sm:h-[12rem]  justify-around items-center  mb-16'>
                    <div className=''>
                        <img className='rounded-xl object-cover px-8 sm:px-0 sm:h-[12rem] ' src="https://res.cloudinary.com/db130iptr/image/upload/v1668738474/marvel_dvbr64.png" alt="Marvel Characters" />
                    </div>
                    <div className='w-5/6 mx-auto sm:m-0  sm:w-1/2 flex flex-col sm:justify-between h-[100%]'>
                        <h2 className='text-xl text-[#65b650]'>Marvel Characters</h2>
                        <p className='text-justify'>It is an introductory application that introduces Marvel characters to users in the project and also with search and pagination features.</p>
                        <div >
                            <a className='btn mr-4' target="_blanked" href="https://marvel-new-version-burakcnk.netlify.app/">Live Demo</a>
                            <a className='btn btn-primary' target="_blanked"  href="https://github.com/burak-caniklioglu/react-marvel-api-2">Github</a>
                        </div>
                    </div>
                </div>



                <div className='flex flex-col sm:flex-row m-auto   sm:gap-8 h-[30rem] sm:h-[12rem]  justify-around items-center  mb-16'>
                    <div className=''>
                        <img className='rounded-xl object-cover px-8 sm:px-0 sm:h-[12rem] ' src="https://res.cloudinary.com/db130iptr/image/upload/v1668739598/math_hui626.png" alt="Mathematic Game" />
                    </div>
                    <div className='w-5/6 mx-auto sm:m-0  sm:w-1/2 flex flex-col sm:justify-between h-[100%]'>
                        <h2 className='text-xl text-[#65b650]'>Mathematic Game</h2>
                        <p className='text-justify'>It is a game with numbers 1-10 that teaches children how to multiply.</p>
                        <div >
                            <a className='btn mr-4' target="_blanked" href="https://burakcnk-mathematic-game.netlify.app/">Live Demo</a>
                            <a className='btn btn-primary' target="_blanked"  href="https://github.com/burak-caniklioglu/react-mathematics-game">Github</a>
                        </div>
                    </div>
                </div>


                <div className='flex flex-col sm:flex-row m-auto   sm:gap-8 h-[30rem] sm:h-[12rem]  justify-around items-center  mb-16'>
                    <div className=''>
                        <img className='rounded-xl object-cover px-8 sm:px-0 sm:h-[12rem] ' src="https://res.cloudinary.com/db130iptr/image/upload/v1668739922/weather_fpuvtq.png" alt="Weather App" />
                    </div>
                    <div className='w-5/6 mx-auto sm:m-0  sm:w-1/2 flex flex-col sm:justify-between h-[100%]'>
                        <h2 className='text-xl text-[#65b650]'>Weather App</h2>
                        <p className='text-justify'>Application that gives instant weather information of the entered city</p>
                        <div >
                            <a className='btn mr-4' target="_blanked" href="https://burakweatherapp.netlify.app/">Live Demo</a>
                            <a className='btn btn-primary' target="_blanked"  href="https://github.com/burak-caniklioglu/react-weather-app-rest-api">Github</a>
                        </div>
                    </div>
                </div>




                <div className='flex flex-col sm:flex-row m-auto   sm:gap-8 h-[30rem] sm:h-[12rem]  justify-around items-center  mb-16'>
                    <div className=''>
                        <img className='rounded-xl object-cover px-8 sm:px-0 sm:h-[12rem] ' src="https://res.cloudinary.com/db130iptr/image/upload/v1668740374/reg_olhmsc.png" alt="Login-Register Page" />
                    </div>
                    <div className='w-5/6 mx-auto sm:m-0  sm:w-1/2 flex flex-col sm:justify-between h-[100%] '>
                        <h2 className='text-xl text-[#65b650]'>Login-Register Page</h2>
                        <p className='text-justify'>Login-Register page for users. Form control is handled by Formik and validation is handled by Yup.</p>
                        <div >
                            <a className='btn mr-4' target="_blanked" href="https://burakcnk-register-app.netlify.app/">Live Demo</a>
                            <a className='btn btn-primary' target="_blanked"  href="https://github.com/burak-caniklioglu/react-formik-registration">Github</a>
                        </div>
                    </div>
                </div>


                <div className='flex flex-col sm:flex-row m-auto   sm:gap-8 h-[30rem] sm:h-[12rem]  justify-around items-center  mb-16'>
                    <div className=''>
                        <img className='rounded-xl object-cover px-8 sm:px-0 sm:h-[12rem] ' src="https://res.cloudinary.com/db130iptr/image/upload/v1668741295/recipe_ercszp.png" alt="Recipe App" />
                    </div>
                    <div className='w-5/6 mx-auto sm:m-0  sm:w-1/2 flex flex-col sm:justify-between h-[100%] '>
                        <h2 className='text-xl text-[#65b650]'>Recipe App</h2>
                        <p className='text-justify'>You can access the recipes related to the entered input.</p>
                        <div >
                            <a className='btn mr-4' target="_blanked" href="https://burakrecipe.netlify.app/">Live Demo</a>
                            <a className='btn btn-primary' target="_blanked"  href="https://github.com/burak-caniklioglu/react-recipe-app">Github</a>
                        </div>
                    </div>
                </div>



            </div>
            

        </section>
    );
}

export default Experience;